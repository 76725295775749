<template>
  <div class="headerTop-links py-2" :class="isScroll && 'sticky'">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="isLanding" class="landing-part">
            <!-- <div v-if="device === 'mobile'">
              <a href="/"  >
                <img class="logo" width="" :src="`${speedSizeDomain}/assets/img/logo.png`" width="300" height="66" alt="flying carpet" test="14.09 09:30" />
              </a>
            </div> -->

            <ul class="d-flex list-inline justify-content-start mb-0 pr-1 flex-wrap" v-if="true">
              <li class="list-inline-item" v-if="false">
                <a href="http://www.fbmnow.com/links/url.php?id=103"
                 :class="`${landingInfo.utm_source} - ${destinationName.he}`"
                  id="whatsapp">
                  <strong>{{phoneNumber}}</strong>
                  <img :src="`${speedSizeDomain}/assets/img/Whatsapp.png`" alt="whatsapp"  width="15" height="15"/>
                </a>
              </li>
              <li class="list-inline-item m-0" v-if="marketerId==='' && device === 'desktop'">
                <div id="st-trigger-effects" class="">
                  <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                    <i class="fa fa-bars"></i>
                  </button>
                </div>
              </li>
              <li class="list-inline-item home-icon">
                <a href="/">
                  <i class="fas fa-home"></i>
                </a>
              </li>
              <li class="list-inline-item topSocialLink">
                <ul class="list-inline top-social">
                  <li class="list-inline-item">
                    <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </li>
              <li class="list-inline-item topFollowUs" v-if="device === 'desktop' && showFacebookLink">
                <a class="" :href="facebookLink" target="_blank" title="נפתח בחלון חדש">{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></a>
              </li>
              <li class="list-inline-item d-flex topCallUs" :class="rtlClassName">
                <a class="" :href="'tel:'+phoneNumber">{{phoneNumber}} <i class="fas fa-phone-alt"></i></a>
                <p class="mb-0 pl-2 pr-2" v-if="isMaccabiAgency">
                  <span>{{ $t("top-header.reservation-center-limit") }}</span>
                </p>
                <p class="mb-0 pl-2 pr-2" v-else>
                  {{ $t("top-header.service-center") }}
                </p>
              </li>
              <!-- <li class="list-inline-item">
                <a id="phone" :class="`${landingInfo.utm_source} - ${destinationName.he}`" :href="`tel:${landingInfo.phone}`">
                  <strong class="mr-2">{{landingInfo.phone}}</strong>
                  <img :src="`${speedSizeDomain}/assets/img/phone1.png`" alt="phone" width="15" height="15"/>
                </a>
              </li> -->
              <li v-show="!isTimerFinished" v-if="isShowBannerTimer">
                <div class="banner-timer" :class="timerColor">
                  <span class="eng-text">{{ timerSubject }}</span> {{ timerText }}

                  <countdown class="timer-flex-position eng-text"  v-if="isWithTimer"
                    :start-time="timerStart" @start="timerStarted"
                    :end-time="timerEnd" @finish="timerFinished">
                    <template v-slot:process="time">
                      <div dir="ltr" class="timer-flex-position">
                        <div class="time-wrap">{{ time.timeObj.d }} <div class="small-text">{{ $t('top-header.banner-timer.days') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.h }} <div class="small-text">{{ $t('top-header.banner-timer.hours') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.m }} <div class="small-text">{{ $t('top-header.banner-timer.minutes') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.s }} <div class="small-text">{{ $t('top-header.banner-timer.seconds') }}</div></div>
                      </div>
                    </template>
                  </countdown>
                </div>
              </li>

              <li class="list-inline-item desktop-logo" v-if="device==='desktop'">
                <a href="/">
                  <img class="logo-img" width="200" height="66" :src="`${speedSizeDomain}/assets/img/logo.png`" alt="flying carpet" test="14.09 09:30" />
                </a>
              </li>
              <li class="list-inline-item" v-if="false">
                <a
                  id="flights_only"
                  :class="`kidum_seo_${landingInfo.utm_source}`"
                  href="flights.php?id=8&amp;destination=facebook_dubai"
                >
                  <strong>
                    <img
                      :src="`${speedSizeDomain}/assets/img/flight1.png`"
                      border="0"
                      style="max-height: 30px"
                      alt="flight1"
                      width="30" height="30"
                    />
                    טיסות בלבד
                  </strong>
                </a>
              </li>
              <li class="list-inline-item" v-if="false">
                <a id="meurganim_only" :class="`kidum_seo_${landingInfo.utm_source}`" :href="`meurganim.php?id=8&amp;destination=${landingInfo.utm_source}_${destinationName.en}`" >
                  <strong><img :src="`${speedSizeDomain}/assets/img/flight5.png`" border="0" style="max-height:30px" width="30" height="30" alt="flight5">טיולים מאורגנים </strong>
                </a>
              </li>
            </ul>

            <ul class="mb-0 pt-2" v-else>
              <li class="list-inline-item" v-if="false">
                <a id="whatsapp" href="http://www.fbmnow.com/links/url.php?id=103" :class="`${landingInfo.utm_source} - ${destinationName.he}`">
                  <img :src="`${speedSizeDomain}/assets/img/Whatsapp.png`" alt="whatsapp" width="15" height="15">
                </a>
              </li>
              <li class="list-inline-item">
                <a id="phone" :class="`${landingInfo.utm_source} - ${destinationName.he}`" :href="`tel:${landingInfo.phone}`" >
                  <img :src="`${speedSizeDomain}/assets/img/phone1.png`" alt="phone1" width="15" height="15">
                </a>
              </li>
            </ul>
            <!-- <div class="header_rightbox_bottom" v-if="device === 'mobile'"> -->
            <div class="header_rightbox_bottom" v-if="false">
              <a href="https://www.flying-carpet.co.il">לדילים נוספים</a>
            </div>
            <!-- <div class="header_rightbox_bottom" v-if="device === 'desktop'"> -->
            <div class="header_rightbox_bottom" v-if="false">
              <!-- <a :href="`http://www.flying.co.il?utm_source=${landingInfo.utm_source}`">לחצו כאן לדילים מדהימים נוספים אצלנו.</a> -->
              <a href="https://www.flying-carpet.co.il">לחצו כאן לדילים מדהימים נוספים אצלנו.</a>
            </div>
          </div>

          <div class="d-flex justify-content-between footer-page" v-else-if="isFooterPage">
            <ul class="list-inline mb-0 d-flex flex-wrap">
              <li v-if="user && !isScroll" style="align-self: center;" class="ml-1">
                <b-button @click="logout" variant="outline-light" size="sm" class="w-100">{{`${user.firstName} ${user.lastName}`}}</b-button>
              </li>
              <li class="list-inline-item m-0" v-if="marketerId==='' && isScroll && device === 'desktop'">
                <div id="st-trigger-effects" class="">
                  <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                    <i class="fa fa-bars"></i>
                  </button>
                </div>
              </li>
              <li class="list-inline-item m-0 home-icon">
                <router-link :to="logoURL">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="list-inline-item topSocialLink">
                <ul class="list-inline top-social">
                  <li class="list-inline-item">
                    <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </li>
              <li class="list-inline-item topFollowUs" v-if="device === 'desktop' && showFacebookLink">
                <a class="" :href="facebookLink" target="_blank" title="נפתח בחלון חדש">{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></a>
              </li>
              <li class="list-inline-item d-flex topCallUs" :class="rtlClassName">
                <a class="" :href="'tel:'+phoneNumber"><span v-if="device === 'desktop'">{{phoneNumber}} </span><i class="fas fa-phone-alt"></i></a>
                <p class="mb-0 pl-2 pr-2" v-if="device === 'desktop' && isMaccabiAgency">
                  {{ $t("top-header.reservation-center-limit") }} &nbsp;
                </p>
                <p class="mb-0 pl-2 pr-2" v-else-if="device === 'desktop'">
                  {{ $t("top-header.service-center") }} &nbsp;
                </p>
              </li>
              <li class="list-inline-item"></li>
            </ul>
            <div class="list-inline-item desktop-logo">
              <router-link to="/">
                <img class="logo-img" width="40" height="40" :src="`${speedSizeDomain}/assets/img/logo.png`" alt="flying carpet" test="14.09 09:30" />
              </router-link>
            </div>
          </div>

          <div class="d-flex justify-content-between" v-else>
            <ul class="list-inline mb-0 d-flex flex-wrap">
              <li v-if="user && !isScroll" style="align-self: center;" class="ml-1">
                <b-button @click="logout" variant="outline-light" size="sm" class="w-100">{{`${user.firstName} ${user.lastName}`}}</b-button>
              </li>
              <li v-if="agencyUser && !isScroll" style="align-self: center;" class="ml-1">
                <b-button @click="agencyLogout" variant="outline-light" size="sm" class="w-100">{{$t('login.log-off') }}</b-button>
              </li>
              <li v-if="marketerId && isFcAgentMarketerMode" style="align-self: center;" class="ml-1" title="show agent list">
                <b-button @click="showAgentListModal" variant="outline-light" size="sm" class="w-100"><i class="fa fa-users"></i></b-button>
              </li>
              <li class="list-inline-item m-0" v-if="marketerId==='' && isScroll && device === 'desktop'">
                <div id="st-trigger-effects" class="">
                  <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                    <i class="fa fa-bars"></i>
                  </button>
                </div>
              </li>
              <li class="list-inline-item m-0 home-icon" v-if="!isMaccabiAgency && (isNotHomePage || isFooterPage) && device === 'desktop'">
                <router-link :to="logoURL">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="list-inline-item topSocialLink" v-if="!enableSearchAgentState">
                <ul class="list-inline top-social">
                  <li class="list-inline-item">
                    <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </li>
              <li class="list-inline-item d-flex " :class="rtlClassName" v-if="false && device === 'desktop'" >
                <a class="" href="/" v-if="marketerId===''"> לתאריכים נוספים &nbsp;</a>
              </li>
              <li class="list-inline-item topFollowUs" v-if="device === 'desktop' && showFacebookLink">
                <a class="" :href="facebookLink" target="_blank" title="נפתח בחלון חדש">{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></a>
              </li>
              <li class="list-inline-item d-flex topCallUs ml-0" :class="[rtlClassName, isBYTE && 'isByte']">
                <a class="" :href="'tel:'+phoneNumber">{{phoneNumber}} <i class="fas fa-phone-alt"></i></a>
                <p class="mb-0 pl-2 pr-2" v-if="isMaccabiAgency">
                  <span>{{ $t("top-header.reservation-center-limit") }}</span><br v-if="device==='mobile'"/>
                  <span>{{ $t("top-header.reservation-center-time") }}</span>
                </p>
                <p class="mb-0 pl-2 pr-2" v-else>
                  <span v-if="enableSearchAgentState">{{ $t("top-header.technical-support") }} &nbsp;</span>
                  <span v-else>{{ $t("top-header.service-center") }} &nbsp;</span>
                </p>
              </li>
              <li class="list-inline-item d-flex whatsapp" :class="rtlClassName" v-if="false">
                <a class="" :href="'tel:'+whatsappNumber">{{whatsappNumber}} <i class="fas fa-phone-alt"></i></a>
                <p class="mb-0 pl-2 pr-2">
                  {{ $t("top-header.whatsapp") }} &nbsp;
                </p>
              </li>

              <li class="list-inline-item logo mx-3 mt-0" v-if="!isMaccabiAgency && device==='mobile' && isBYTE" style="position: absolute; left: 0;">
                <router-link :class="['navbar-brand', 'order-1', 'mobile_logo', marketerId === ''? 'filtered_logo': '']" :to="logoURL">
                  <img :class="['img-fluid', 'logo-img', marketerLogo === '' ? '' : 'marketer_logo']" :src="srcLogo" alt="logo" height="45"/>
                </router-link>
              </li>

              <li class="list-inline-item"></li>
              <li v-show="!isTimerFinished" v-if="isShowBannerTimer" class="m-auto">
                <div class="banner-timer" :class="timerColor">
                  <span class="eng-text">{{ timerSubject }}</span> {{ timerText }}

                  <countdown class="timer-flex-position eng-text" v-if="isWithTimer"
                    :start-time="timerStart" @start="timerStarted"
                    :end-time="timerEnd" @finish="timerFinished">
                    <template v-slot:process="time">
                      <div dir="ltr" class="timer-flex-position">
                        <div class="time-wrap">{{ time.timeObj.d }} <div class="small-text">{{ $t('top-header.banner-timer.days') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.h }} <div class="small-text">{{ $t('top-header.banner-timer.hours') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.m }} <div class="small-text">{{ $t('top-header.banner-timer.minutes') }}</div></div>
                        <div class="time-wrap">{{ time.timeObj.s }} <div class="small-text">{{ $t('top-header.banner-timer.seconds') }}</div></div>
                      </div>
                    </template>
                  </countdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="agentListForPricePlan" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("price-plan.agency-list") }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100 agent-list" v-if="agencyListForPricePlan && agencyListForPricePlan.length">
          <b-list-group>
            <!-- <b-list-group-item class="my-1" v-for="(agency, inx) in agencyListForPricePlan" :key="inx" :href="`?${agency.homeUrl.split('?')[1]}&fc-agent-mode`" variant="primary"> -->
            <b-list-group-item class="my-1" v-for="(agency, inx) in agencyListForPricePlan" :key="inx" :href="`${agency.homeUrl}&fc-agent-mode`" variant="primary">
              <div class="agency-code">{{ agency.odyCode }}</div>
              <!-- <img class="mx-1" alt="after-booking" :src="agency.homeContent ? agency.homeContent.logoUrls: 'https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://image.flyingcarpet.co.il/upload/image/logo/logo_20210421185205432.png'" width="50" height="50"/> -->
             <img class="mx-1" alt="after-booking" :src="agency.homeContent ? agency.homeContent.logoUrls: 'https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://image.flyingisr.com/upload/image/logo/logo_20210421185205432.png'" width="50" height="50"/>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-else class="w-100 text-center">no-content</div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="ok();">
          {{ $t("login.cancel") }}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { BButton, VBToggle, BListGroup, BListGroupItem } from 'bootstrap-vue';
import TemplateTopHeader from './TemplateTopHeader';

export default {
  name: 'TopHeaderTheme0',
  components: {
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
    countdown: () => import('@/components/atoms/CountDown'),
    BButton,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  extends: TemplateTopHeader,
};
</script>
<style>
.modal-header .close {
  margin: 0rem;
}
.agent-list .list-group-item + .list-group-item {
  border-top-width: thin;
}
.agent-list .list-group-item {
  display: inline-flex;
  justify-content: space-between;
}
.agent-list .list-group-item img{
  inline-size: fit-content;
  max-width: 200px;
}
.agent-list .list-group-item .agency-code{
  align-self: center;
}

</style>
<style scoped>
.logo-img {
  height: 45px;
  width: 100%;
  object-fit: fill;
}
.mobile_logo {
  margin: 0;
}
.sticky{
  box-shadow: #053356 0px 1px 40px;
}
li.phone-number-rtl {
  direction: ltr;
}
ul {
  /* font-family: "Poppins", sans-serif; */
  font-family: "ploni";
  font-weight: bold;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  align-self: center;
}
.list-inline {
  padding-right: 0px;
}
.landing-part .logo {
  max-width: 180px;
  float: left;
  margin-right: 6rem;
}
.landing-part ul li img {
  max-width: 10.8rem;
  margin-right: 5px;
}
/* .landing-part ul {
  line-height: 40px;
} */
.landing-part .header_rightbox_bottom {
  display: block;
  background-color: #fff;
  padding: 8px 20px 9px 30px;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  font-size: 1.2em;
}
.landing-part .header_rightbox_bottom a {
  color: #0061ab;
}
.landing-part .header_rightbox_bottom:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 45px solid white;
  border-left: 45px solid transparent;
  z-index: 0;
}
.footer-page .topCallUs a i{
  font-size: 1.3rem;
}
.topCallUs {
  align-items: center;
}
.landing-part .list-inline-item.desktop-logo {
  margin: auto;
}
.list-inline-item:last-child {
  border: 0 !important;
}
.banner-timer{
  font-size: 1.5rem;
  text-align: center;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  max-width: 40rem;
}
.time-wrap {
  width: 2.5rem;
}
.small-text {
  font-size: 0.6rem;
  font-family: "ploni";
}

.banner-timer.black{
  background-color: #0e1313;
  border: #efe64f solid;
  color:#efe64f;
}
.banner-timer.red{
  background-color: #e8211d;
  border: white solid;
  color:white;
}
.banner-timer.blue{
  background-color: #331cfc;
  border: #f5f7ff solid;
  color:#f5f7ff;
}
.banner-timer.light-black{
  background-color: #0a0a0a;
  border: #f5be90 solid;
  color:#f5be90;
}
.banner-timer.yellow{
  background-color: #ffbe69;
  border: #4a0000 solid;
  color:#4a0000;
}
.banner-timer.light-pink{
  background-color: #ffece7;
  border: #d14356 solid;
  color:#d14356;
}
.banner-timer.purple{
  background-color: #211d54;
  border: #faf9ff solid;
  color:#faf9ff;
}

.eng-text{
  font-family: antonio;
  vertical-align: middle;
}
.timer-flex-position {
  display: inline-flex;
}
li.home-icon {
  height: 24px;
}
li.home-icon > a {
  font-size: 1.3rem;
}
@media (max-width: 1200px) {
  .landing-part .header_rightbox_bottom {
    display: none;
  }
}
@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  .landing-part .logo {
    margin: 20px 0 35px 3rem;
    max-width: 160px;
  }
 .landing-part .header_rightbox_bottom {
    display: initial;
    top: 70px;
  }
  .banner-timer{
    font-size: 1rem;
    margin-top: 0.5rem;
    line-height: 1.3rem;
  }
  .banner-timer.landing-timer{
    bottom: 0;
  }
  .headerTop-links ul:not(.top-social) > li.list-inline-item:not(:last-child) {
    margin-top: 0.5rem;
    border: none;
  }
  .headerTop-links ul:not(.top-social) > li.list-inline-item:not(:last-child) a{
    padding-right: 0rem;
  }
  .topCallUs.isByte {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    padding: 0 !important;
  }
}
</style>
